<template>
  <v-app>
    <Header v-if="ShowHeader" v-bind:user="user" />
    <v-dialog v-model="Inovice.Open" width="60vw">
      <v-container class="background pt-0" fluid>
        <v-row justify="end" class="pa-0 ma-0 pb-0 mb-0">
          <v-icon large @click="Inovice.Open = !Inovice.Open" class="pt-2">mdi-close-circle-outline</v-icon>
        </v-row>
        <InoviceDetails :user="user"></InoviceDetails>
      </v-container>
    </v-dialog>

    <div v-if="!ShowAPP" class="text-center pt-16">
      <v-img src="/loading.png" height="150" contain class="Loading mt-10" />
    </div>
    <div v-else>
      <div v-show="Locked">
        <h1 class="ma-16 rounded-xl pa-10 error white--text text-center" @click="Inovice.Open = true">
          {{ Dictionary.GetString("systemshutdown").toString() }}
        </h1>
      </div>
      <div v-show="!Locked">
        <v-container fluid class="pa-0 ma-0">
          <transition name="slide-left" mode="out-in">
            <router-view :key="$route.fullPath" v-bind:user="user" />
          </transition>
        </v-container>
      </div>
    </div>
  </v-app>
</template>

<script>
// JS Scripts
import { differenceInDays } from "date-fns";
import Dictionary from "../src/SubClasses/Dictionary.js";
import GeneralClasses from "../src/SubClasses/GeneralClasses.js";

// Components
import Header from "./components/Shared/Header.vue";
import InoviceDetails from "./components/Shared/InoviceDetails.vue";

// JS Libraries
import moment from "moment";

export default {
  name: "App",
  components: { Header, InoviceDetails },
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),

      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).ATs.LiekasseAT),
      },
      Inovice: {
        Open: false,
      },

      moment: moment,

      user: {
        PrivateSetting: [],
        SevDesk: [],
        PublicSetting: [],
        Liekasse: [],
      },
      Locked: false,
      ShowAPP: false,
    };
  },
  watch: {
    user(Value) {
      this.user = Value;
    },
    $route() {
      this.HeaderSetting();
      this.ShowAPP = false;
      setTimeout(() => {
        this.ShowAPP = true;
      }, 1000);
    },
  },
  methods: {
    GeneralData: function () {
      this.$http
        .get(this.GeneralClasses.LiekasseDomain() + window.atob(GeneralClasses.API8SPublic()), this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((ResultPublicSetting) => {
          this.user.PublicSetting = JSON.parse(ResultPublicSetting.data.options);
          this.$http
            .get(this.GeneralClasses.LiekasseDomain() + window.atob(GeneralClasses.API8SPrivate()), this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
            .then((ResultPrivateSetting) => {
              this.user.PrivateSetting = JSON.parse(ResultPrivateSetting.data.options);
              if (ResultPrivateSetting.data.SevDesk != null) {
                this.user.SevDesk = ResultPrivateSetting.data.SevDesk;
                this.user.PrivateSetting.Locked = this.CloseSystem(this.user.SevDesk.objects);
              }
              this.$http
                .get(this.GeneralClasses.LiekasseDomain() + window.atob(GeneralClasses.API12()), this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
                .then((ResultLiekasse) => {
                  this.user.Liekasse.push(ResultLiekasse.data);
                })
                .catch((Error) => {
                  this.user.Liekasse = [];
                });
            })
            .catch((Error) => {
              this.user.PrivateSetting = [];
              this.user.SevDesk = [];
            });
        })
        .catch((Error) => {
          this.user.PublicSetting = [];
        });
    },
    CheckIfInstalled: function () {
      var Pages = ["/" + this.LSSettings.UserInfo.Language + "/main/panel/pages/install", "/" + this.LSSettings.UserInfo.Language + "/main/panel/pages/install/"];
      this.$http
        .get(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API8SPublic()), this.GeneralClasses.GetHeaderValue())
        .then((CheckSettingResult) => {
          if (CheckSettingResult.data.length == 0) {
            if (!Pages.includes(this.$route.fullPath)) {
              window.location.replace("/" + this.LSSettings.UserInfo.Language + "/main/panel/pages/install");
            }
          } else {
            if (Pages.includes(this.$route.fullPath)) {
              window.location.replace("/" + this.LSSettings.UserInfo.Language + "/main/panel/pages/products");
            } else {
              this.GeneralData();
            }
          }
        })
        .catch((Error) => {
          if (!Pages.includes(this.$route.fullPath)) {
            window.location.replace("/" + this.LSSettings.UserInfo.Language + "/main/panel/pages/install");
          }
        });
    },
    HeaderSetting: function () {
      var Pages = ["/" + this.LSSettings.UserInfo.Language + "/main/panel/pages/install", "/" + this.LSSettings.UserInfo.Language + "/main/panel/pages/install/", "/" + this.LSSettings.UserInfo.Language + "/main/panel/pages/login", "/" + this.LSSettings.UserInfo.Language + "/main/panel/pages/login/", "/" + this.LSSettings.UserInfo.Language + "/main/panel/pages/userscreen", "/" + this.LSSettings.UserInfo.Language + "/main/panel/pages/userscreen/", "/" + this.LSSettings.UserInfo.Language, "/" + this.LSSettings.UserInfo.Language + "/"];
      if (Pages.includes(this.$route.fullPath)) {
        this.ShowHeader = false;
      } else {
        this.ShowHeader = true;
      }
    },
    LanguageSettings: function () {
      if (JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).UserInfo.Language == "ar") {
        this.$vuetify.rtl = true;
      } else {
        this.$vuetify.rtl = false;
      }
    },
    MainInstall: function () {
      this.CheckIfInstalled();
      this.HeaderSetting();
      this.LanguageSettings();
    },
    CheckUser: function () {
      // if (JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).ATs.LiekasseAT != null) {
      //   this.$http.get(this.GeneralClasses.LiekasseDomain() + window.atob(GeneralClasses.API12()), this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT)).then((ResultLiekasse) => {
      //     if (ResultLiekasse.data.isLogged == 0) {
      //       localStorage.clear();
      //       window.location.replace("/kasse");
      //     }
      //   });
      // }
    },
    CloseSystem: function (objects) {
      if (objects.length >= 3) {
        // Number of milliseconds in one day
        const oneDay = (24 * 60 * 60 * 1000) + 0.5;
        // Current date
        const currentDate = new Date();

        let SecondInvoice = objects[0];
        let originalDate = new Date(SecondInvoice.invoiceDate);
        // Add 15 days
        let WaitingDateTimestamp = originalDate.setDate(originalDate.getDate() + 15);
        // Convert the timestamp to a Date object
        let WaitingDate = new Date(WaitingDateTimestamp);
        // Format WaitingDate using moment
        let formattedWaitingDate = moment(WaitingDate).format('YYYY-MM-DD');
        // Calculate the difference in days and round the result
        const diffInDays = (WaitingDate - currentDate) / oneDay;

        console.log('Number of days between the two dates:', diffInDays);

        if (diffInDays <= 0) {
          // Close System
          this.Locked = true;
          return false;
        } else {
          this.Locked = false;
          return formattedWaitingDate;
        }

      } else {
        this.Locked = false;
        return false;
      }
    },

  },
  created() {
    this.MainInstall();
  },
  mounted() {
    this.ShowAPP = true;
    setInterval(() => {
      if (this.user.PrivateSetting.length != 0) {
        if (this.user.Liekasse[0] != undefined) {
          if (this.user.Liekasse[0].email != "superadmin@liekasse.de") {
            //
          } else {
            //
          }
        }
      }
    }, 100);
    setInterval(() => this.CheckUser(), 10000);
  },
};
</script>
<style>
/* Import Local Google Fonts */
@font-face {
  font-family: "Roboto";
  src: url("/fonts/Roboto-Bold.ttf") format("truetype"), url("/fonts/Roboto-Bold.woff") format("woff"), url("/fonts/Roboto-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Tajawal";
  src: url("/fonts/Tajawal-Bold.ttf") format("truetype"), url("/fonts/Tajawal-Bold.woff") format("woff"), url("/fonts/Tajawal-Bold.woff2") format("woff2");
}

* {
  font-family: "Roboto", "Tajawal" !important;
}

body {
  padding: 0 !important;
}

.ZeroSpace {
  margin: 0 !important;
  padding: 0 !important;
}

.v-application[class*="text-"] {
  font-family: "Roboto", "Tajawal" !important;
}

.NoNumberControll input::-webkit-outer-spin-button,
.NoNumberControll input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  width: 0px;
  background: darkgrey;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px lightgray;
}

::-webkit-scrollbar-thumb {
  background: gray;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active,
.slide-top-enter-active,
.slide-top-leave-active {
  transition-duration: 0.8s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

.slide-top-leave-active,
.slide-top-enter {
  opacity: 0;
  transform: translate(0, 2em);
}

.NoNumberControll input[type="number"] {
  -moz-appearance: textfield;
}

.Pointer {
  cursor: pointer;
}

.OverflowHidden {
  overflow: hidden;
}

.FontCaption {
  font-size: 0.7rem !important;
}

.Block {
  display: block;
  width: 100%;
}

.Loading {
  animation: Loading 1s infinite;
}

.v-icon.outlined {
  border: 1px solid currentColor;
  border-radius: 50%;
  padding: 2px;
}

@keyframes Loading {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(+50px);
  }

  100% {
    transform: translateY(0);
  }
}

.Warning {
  animation: Warning 1s infinite;
}

@keyframes Warning {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(15px);
  }

  100% {
    transform: translateX(0);
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  .PrintArea,
  .PrintArea * {
    visibility: visible;
  }

  .PrintArea {
    position: absolute;
    top: 0;
  }
}
</style>